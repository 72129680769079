exports.components = {
  "component---src-pages-16-rzeczy-js": () => import("./../../../src/pages/16-rzeczy.js" /* webpackChunkName: "component---src-pages-16-rzeczy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-index-js": () => import("./../../../src/pages/blog/en/index.js" /* webpackChunkName: "component---src-pages-blog-en-index-js" */),
  "component---src-pages-blog-pl-index-js": () => import("./../../../src/pages/blog/pl/index.js" /* webpackChunkName: "component---src-pages-blog-pl-index-js" */),
  "component---src-pages-dziekuje-js": () => import("./../../../src/pages/dziekuje.js" /* webpackChunkName: "component---src-pages-dziekuje-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-selenium-zadanie-rekrutacyjne-dziekuje-js": () => import("./../../../src/pages/selenium-zadanie-rekrutacyjne-dziekuje.js" /* webpackChunkName: "component---src-pages-selenium-zadanie-rekrutacyjne-dziekuje-js" */),
  "component---src-pages-selenium-zadanie-rekrutacyjne-js": () => import("./../../../src/pages/selenium-zadanie-rekrutacyjne.js" /* webpackChunkName: "component---src-pages-selenium-zadanie-rekrutacyjne-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

